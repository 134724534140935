.background {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalWrapper {
    width: 440px;
    height: 500px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    position: relative;
    z-index: 10;
    border-radius: 5px;
    border: 1px solid #a8a8a8;
}

.modalWrapper p,
label {
    color: #4c5357;
    font-size: 1.1rem;
}
