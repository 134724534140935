.modal {
    .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30px;
        text-align: center;
    }
    .image {
        width: 55%;
        margin-bottom: 25px;
    }
}

.titleMessage {
    color: #003349;
    word-break: break-word;
}

.descriptionMessage {
    color: #2c3439;
    line-height: 24px;
    margin: 1rem 0 1.5rem 0;
    word-break: break-word;
}

.closePageButton {
    border-color: transparent;
    color: #fff;
    background-color: #0078ab;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:focus {
        color: #fff;
        background-color: #0078ab;
    }
    width: 238px;
}

.buttonGroup {
    margin: auto;
    display: flex;
    column-gap: 15px;
    justify-content: space-around;
}

.alertContainer {
    display: flex;
}

.link {
    min-width: fit-content;
}

.noTextDecoration {
    text-decoration: none;
    color: #4695bc;
}
