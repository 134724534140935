.financesTableTwoColumns {
    display: grid;
    grid-template-columns: 5fr 4fr;
}

.contentCheckbox {
    height: 18px;
    width: 18px;
    background: #fbfbfc;
    border: 2px solid #99adb6;
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
}

.smallNoteText {
    font-family: "Public Sans", sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}

.emailDisabledBox {
    margin-top: 25px;
    margin-bottom: 10px;
}

.saveDiv {
    margin-top: 32px;
    display: flex;
}

.loadingProgress {
    color: #0078ab;
    display: flex;
    margin-left: 5px;
    margin-top: 8px;
}

.fetchingLoadingProgress {
    color: #0078ab;
    display: flex;
    margin-left: 30px;
    margin-top: 30px;
}

.loadingProgressText {
    color: #0078ab;
    display: inline-block;
    margin-left: 5px;
    margin-top: 4px;
}

.button {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 154px;
    height: 40px;
    background: #0078ab;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
}

.buttonDisabled {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 154px;
    height: 40px;
    background: #707579;
    border-radius: 4px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.resetButton {
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: 95px;
    height: 40px;
    color: #0078ab;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
}

.datesContainer {
    display: flex;
    margin: 1.5rem 0 1.5rem 0;
}

.dates {
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
        margin-left: 2.6rem;
    }
    & input {
        width: 100px !important;
        height: 25px !important;
        font-size: 1rem !important;
        padding: 0 0 0 5px !important;
    }
}

.datesTitle {
    color: #2c3439;
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

@mixin turnoverBase() {
    border: 1px solid black;
    border-radius: 4px;
    width: 20px;
    height: 20px;
}

.turnover {
    &ExplanationBox {
        margin: 20px 0;
    }

    &ExplanationText {
        margin-left: 12px;
        font-size: 12px;
    }

    &Container {
        display: flex;
        align-items: center;
    }

    &Zero {
        @include turnoverBase();
        background-color: #690101;
    }

    &Two {
        @include turnoverBase();
        background: linear-gradient(-45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 49%, rgba(105, 1, 1, 1) 49%, rgba(105, 1, 1, 1) 100%);
    }

    &Three {
        @include turnoverBase();
        background: linear-gradient(-45deg, rgba(105, 1, 1, 1) 0%, rgba(105, 1, 1, 1) 49%, rgba(255, 255, 255, 1) 49%, rgba(255, 255, 255, 1) 100%);
    }
}

/* Use global to prevent adding a hash to this specific class from antd DatePicker */
:global(.ant-picker-suffix) {
    & svg {
        color: gray;
        font-size: 20px;
    }
}
