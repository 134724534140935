.title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    margin-left: 63px;
    top: 130px;
}

.arrow {
    margin-top: 90px;
    cursor: pointer;
    width: 11%;
    text-decoration: none;
    outline: none;
    color: inherit;
}
