.button {
    font-weight: 400;
    cursor: pointer;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 1rem 2.75rem;
    font-size: 1rem;
    line-height: 1.5;
    display: inline-flex;
    align-items: center;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button:disabled {
    cursor: default;
    opacity: 0.25;
}

.primary {
    color: #fff;
    background-color: #0078ab;
    border-color: #0078ab;
}

.secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.primaryOutlined {
    color: #0078ab;
    border-color: #0078ab;
    background-color: #fff;
}

.primaryOutlinedNarrow {
    color: #0078ab;
    border-color: #0078ab;
    padding: 0.7rem 2.75rem;
    background-color: #fff;
}

.secondaryOutlined {
    color: #0078ab;
    border-color: #969a9c;
    background-color: #fff;
}

.secondaryOutlinedNarrow {
    color: #0078ab;
    border-color: #969a9c;
    padding: 0.7rem 5.75rem;
    height: 40px;
    background-color: #fff;
}
