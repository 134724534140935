.modalBody {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    text-align: center;
}

.modalBody p {
    font-size: 1.2rem;
}

.modalBody button {
    padding: 8px 50px 8px 50px;
    background-color: #6ba342;
    color: #fff;
    border: none;
    margin-bottom: 0.6rem;
}

.textDanger {
    color: #d43354;
}

.imgBody {
    max-width: 100%;
    width: 137px;
    margin-bottom: 1rem;
}

.h3 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    font-size: 24px;
    font-family: "Wigrum", sans-serif;
    font-weight: 600;
    color: #003349;
}

.skipCancellation {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #0078ab !important;
    cursor: pointer;
}
