.addNightHeader {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 50px;
    margin-left: 50px;
    margin-bottom: 32px;
}

.title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 800;
    top: 130px;
}

.reservationHeader {
    display: grid;
    grid-template-columns: 1fr 2fr;
    max-width: 480px;
}

.reservationHeaderSubTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #2c3439;
}

.reservationHeaderText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #2c3439;
}
.reservationHeaderLink {
    color: #4695bc;
}

.noTextDecoration {
    text-decoration: none;
    color: #4695bc;
}
