.containerAmounts {
    display: grid;
    grid-template-columns: 2fr 16fr;
    box-sizing: border-box;
}

.financesInnerTableText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2c3439;
}

.bold {
    font-weight: 700 !important;
}

.negativeValue {
    color: #d62e4f;
}

.positiveValue {
    color: #6ba342;
}

.financesInnerTableTextAlt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #000000;
    padding-right: 14px;
}
