.container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 24px;
    gap: 24px;
    margin-top: 24px;
    margin-left: 50px;
    padding-bottom: 100px;
    .tabs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        .link {
            align-self: end;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            font-style: normal;
            font-weight: 400;
            color: #4d7080;
            border-bottom: 1px solid #ccd6db;
            cursor: pointer;
            &.active {
                font-weight: 600;
                color: #003349;
                border-bottom: 3px solid #99adb6;
            }
        }
    }
}

.waitSpinner {
    margin: 0;
    height: 100vh; /* use 100% of the height of the viewport */
    display: grid;
    place-items: center;
}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.126316px;
    color: #2c3439;
    padding-top: 32px;
    padding-bottom: 30px;
}

.containerItem {
    padding-bottom: 32px;
}

.body {
    background: #ffffff;
    border-radius: 4px;
    padding-left: 32px;
    padding-right: 32px;
}
