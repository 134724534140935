.checkContainer {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 0.1rem;
}

.check {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transition: all 150ms;
}

.labelCheck {
    margin-left: 0.5rem;
    margin-bottom: 0;
}
